.modal-backdrop.show {
  opacity: 0.2 !important;
}

.modalContainerFilterOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 4, 13, 0.904);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalFilterView {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  height: 80%;
  width: 90%;
  animation: fadeIn 0.3s ease forwards;
}

.modalFilterViewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & h2 {
    margin-bottom: -10px;
  }
}


@keyframes fadeIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}