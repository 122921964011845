.logout__button {
  background-color: #1D77D4 !important;
  color: white;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 20px;
  border-radius: 5px;
  border: none !important;
  font-weight: bolder;
}

.nav-item .nav-link {
  width: 100%;
}

.nav-link .align-items-center {
  width: 100%;
  justify-content: space-between !important;
}

@media (max-width: 991.98px) {
  .collapse-fullscreen {
    width: 100% !important;
    height: 100vh !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 1050;
    opacity: 1 !important;
    background-color: rgb(4, 20, 35) !important;
    border-radius: 0 !important;
    /* Fondo oscuro */
    overflow-y: auto;
  }

  .collapse-fullscreen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .navbar__header {
    background-color: #f5f8fb !important;
    margin: 0 !important;
    padding: 10px;
  }

  .nav__link {
    color: #262B40 !important;
  }

  .dropdown__header {
    background-color: #f5f8fb !important;
    padding: 20px;
    position: relative;
  }

  .dropdown-menu-right {
    position: absolute !important;
    width: 10px !important;
    top: 20px !important;
  }
}

.filter-section {
  background-color: rgb(4, 20, 35);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  margin: 25px;
  padding: 20px;
  border-radius: 5px;
}

.filter-section .btn-filter {
  border-radius: 3px;
  border: none;
  background: white;
  min-height: 42px;
}