.panel-primary {
  position: relative;
}

.list-group {
  border: 1px solid rgb(114, 114, 114);
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 300px;
  margin-bottom: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: -2px 10px 5px 0px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -2px 10px 5px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -2px 10px 5px 0px rgba(0, 0, 0, 0.23);
}

.list-group-item:hover {
  cursor: pointer;
  background-color: #101F55;
  color: white;
}